<template>
  <div>
    <b-overlay
      :show="!invalid"
    >
      <div class="text-center mt-4">
        <vuexy-logo />
      </div>
      <b-card
        v-if="invalid"
        class="text-center mt-4 w-50 mx-auto"
      >
        <div
          class="d-flex justify-content-center align-items-center"
          style="min-height: 300px"
        >
          <div>
            <feather-icon
              icon="FrownIcon"
              size="72"
              color="#ff9f43"
            />
            <h2 class="mt-1">
              Oops!!
            </h2>

            <h5 class="mb-3">
              Looks like the link is broken.
            </h5>
            <b-button
              variant="primary"
              class="cursor-pointer"
              @click="$router.replace({ name: 'login'})"
            >
              Go to login
            </b-button>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import { BCard, BOverlay, BButton } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import VuexyLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BButton,
    VuexyLogo,
  },
  data() {
    return {
      invalid: false,
    }
  },
  created() {
    localStorage.clear()
  },
  mounted() {
    const decline = Boolean(this.$route.query.decline ?? 0)
    useApollo.auth.verifyInvoiceLogin({
      input: {
        email: this.$route.query.email,
        otp: Number(this.$route.query.otp),
        record: Number(this.$route.query.record),
        decline,
      },
    }).then(response => {
      const userData = response.data.verifyInvoiceViaOTP
      const action = decline ? 'rejected' : 'approved'
      localStorage.setItem('userData', JSON.stringify(userData.user))
      useJwt.setToken(userData.access_token)
      setTimeout(() => {
        this.$router.replace({ name: 'home' }).then(() => {
          this.$store.dispatch('project/STORE_SELECTED_PROJECT', null)
          this.showSuccessMessage({
            data: {
              message: `You have successfully ${action} the invoice`,
            },
          })
        })
      }, 500)
    }).catch(() => {
      this.invalid = true
    })
  },
}
</script>
